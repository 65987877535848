import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import param from 'can-param'
import Events from './Events'
import EventsStore from '../../stores/events'
import config from '../../config'
import { Get } from '../../utils/request'
import moment from 'moment'
import { sortBy } from 'lodash'

const getFilterData = async (
  value,
  apiUrl,
  filter,
  dataField,
  dataLabelField,
  additionalQuery
) => {
  const url = `${apiUrl}${value ? `?${filter}=${value}` : ''}${
    value ? '&' : '?'
  }${additionalQuery || ''}`
  const result = await Get(url)
  if (result[dataField]) {
    return result[dataField].map((i) => ({
      id: i.id,
      n: i.n,
      label: i[dataLabelField],
      ...(i.opportunityName && { opportunityName: i.opportunityName }),
    }))
  }
  return []
}

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1)

const EVENT_KINDS = sortBy([
  'assitedLockEnabled',
  'assitedLockDisabled',
  'paywallEnabled',
  'paywallDisabled',
  'newSearchButton',
  'purchaseCreditsButton',
  'contactSalesButton',
  'unlockPaywallButton',
  'filtersApplied',
  'sortingApplied',

  'brandCreated',
  'competitorsUpdated',
  'confirmEmailSent',
  'contactsRequested',
  'creditsSpend',
  'creditsTopup',
  'csvExported',
  'discoveryFirstSeen',
  'emailVerified',
  'keywordsUpdated',
  'leadsAddedToCompetitors',
  'leadsAddedToIrrelevant',
  'leadsRemovedFromIrrelevant',
  'leadsAddedToList',
  'leadsAddedToProspects',
  'leadsTagged',
  'prospectsTagged',
  'partnersTagged',
  'listCreated',
  'noteAddedToProspect',
  'onboardingDone',
  'productCreated',
  'researchCompleted',
  'researchStarted',
  'subscriptionActivated',
  'subscriptionCancelled',
  'subscriptionChanged',
  'trialExpired',
  'userCreated',
  'userLoggedIn',
  'userLoggedOut',

  'brandsAddedToComparison',
  'gapSearch',
  'gapFiltersApplied',
  'gapSortingApplied',
  'landscapeSearch',
  'gapAddedToRelevant',
  'showDetails',
]).map((str) => ({ label: str, value: str }))

const filteredSearchConfig = {
  keys: [
    { id: 'kinds', label: 'Kind' },
    { id: 'brandIds', label: 'Brand' },
    { id: 'userIds', label: 'User' },
  ],
  expressions: [{ id: 'eq', label: '=' }],
  values: {
    kinds: () => new Promise((resolve) => resolve(EVENT_KINDS)),
    brandIds: async (value) =>
      await getFilterData(
        value,
        '/api/admin/brands',
        'filters[name]',
        'brands',
        'name'
      ),
    userIds: async (value) =>
      await getFilterData(
        value,
        '/api/admin/users',
        'filters[name]',
        'users',
        'name'
      ),
  },
  groupBy: {
    touchpointIds: 'opportunityName',
  },
}

@withRouter
@observer
class _Events extends React.Component {
  constructor(props) {
    super(props)
    this.store = new EventsStore()
    this.state = { filters: [] }
  }

  onChangeSort = (orderByNew) => (e) => {
    const { listProps } = this.store
    const { order, orderBy } = listProps
    let orderNew = ''
    if (orderBy === orderByNew) {
      if (order === 'desc') {
        orderNew = 'asc'
      } else {
        orderNew = 'desc'
      }
    } else {
      orderNew = 'desc'
    }

    this.store.onChangeSort(orderNew, orderByNew)
  }

  onChangePage = (e, page) => this.store.onChangePage(page)

  onChangeRowsPerPage = ({ target: { value } }) =>
    this.store.onChangeRowsPerPage(value)

  onChangeFilters = (name) => (object) => {
    if (name === 'date') {
      const dateFilters = {
        startAt: moment(object[0]).format('YYYY-MM-DD'),
        endAt: moment(object[1]).format('YYYY-MM-DD'),
      }
      this.store.onChangeFilterGroup(dateFilters)
    } else {
      let value = null
      if (object.hasOwnProperty('target')) {
        value = object.target.value
      } else {
        value = object
      }
      this.store.onChangeFilter(name, value)
    }
  }

  onFilteredSearchChange = (filters = []) => {
    let eventFilters = {}
    const { filters: storeFilters } = this.store
    const eventFilterFields = filteredSearchConfig.keys.map(({ id }) => id)
    eventFilterFields.forEach((key) => (eventFilters[key] = []))
    filters
      .filter((f) => f.key && f.value)
      .forEach(({ key, value: { value, id } }) => {
        eventFilters[key.id].push(value || id)
      })

    eventFilters = { ...storeFilters, ...eventFilters }

    for (let key in eventFilters) {
      if (eventFilters.hasOwnProperty(key)) {
        if (!eventFilters[key] || !eventFilters[key].length) {
          delete eventFilters[key]
        }
      }
    }

    this.setState(
      { filters, importQueryString: param({ filters: eventFilters }) },
      () => {
        this.store.onChangeFilterGroup(eventFilters)
      }
    )
  }

  onClickRow = (e, id) => {
    // if (e.ctrlKey || e.metaKey) {
    //   window.open(`/events/${id}`, '_blank')
    // } else {
    //   this.props.history.push(`/events/${id}`)
    // }
  }

  get importCSVUrl() {
    const { importQueryString } = this.state
    return `/api/admin/conversions/csv${
      importQueryString ? `?${importQueryString}` : ''
    }`
  }

  onBulkAction = async (itemIds, status, reason) => {
    return this.store.setBulkStatus(itemIds, status, reason)
  }

  render() {
    const { listProps, events, totalEvents } = this.store
    const { filters } = this.state
    const filteredSearchProps = {
      ...filteredSearchConfig,
      filters,
      onChange: this.onFilteredSearchChange,
    }

    return (
      <Events
        {...this.props}
        {...listProps}
        totalEvents={totalEvents}
        items={events}
        importLink={this.importCSVUrl}
        filteredSearchProps={filteredSearchProps}
        onChangeSort={this.onChangeSort}
        onChangeFilters={this.onChangeFilters}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onClickRow={this.onClickRow}
        onBulkAction={this.onBulkAction}
      />
    )
  }
}

export default _Events
